import React from 'react'
import Div from 'common/Div.js'
import Popup from 'common/Popup.js'
import Map from 'common/Map.js'
import OrderBy from 'common/OrderBy.js'
import EditSwitch from 'common/NewEditSwitch.js'
import Card from 'common/Card.js'
import Format from 'util/format/Format.js'
import { CheckBox } from 'common/FormUI.js'
import Delv from 'delv/delv-react'
import AddCircle from '@material-ui/icons/AddCircle'
import MultiSelect, { StatelessMultiSelect } from 'common/MultiSelect.js'
import Round from 'components/round/index.js'
import * as permissions from 'util/permissions.js'
import NoneFound from 'common/NoneFound/index.js'
import ProofOfIssuance from './proofOfIssuance/index.js'
import AssetManagerCard from './AssetManagerCard.js'
import BrokerAllocationForm from './BrokerAllocationForm.js'
import AllocationsCard from './AllocationsCard.js'
import CapitalSummary from './CapitalSummary.js'
import IssuanceForm from './IssuanceForm.js'
import ManagePagination from './ManagePagination.js'
import HoldingPagination from './HoldingPagination.js'
import { MintQuery, HoldingsQuery } from './queries.js'
import './manage.scss'

const DistributionCard = ({
    name, units, onClick, isSelected,
}) => (
    <Card.card>
        <CheckBox value={isSelected} onChange={onClick} />
        <Card.section>
            <Card.label>Name</Card.label>
            {name}
        </Card.section>
        <Card.section />
        <Card.section>
            <Card.label>Units</Card.label>
            {Format.number(units)}
        </Card.section>
    </Card.card>
)

const Manage = ({
    klass, orderByData, ...props
}) => {
    const tranche = {
        ...props,
        type: klass?.assetByAssetId?.type,
        term: klass?.trancheBondDatumByRoundId?.term,
        coupon: klass?.trancheBondDatumByRoundId?.coupon,
    }
    const isIssuer = permissions.isIssuer(props)
    const canEdit = permissions.canEditDefault(props)
    const roundProps = {
        ...klass,
        ...props,
        ...tranche,
        unitValue: klass?.securityBySecurityId.unitValue,
    }
    return (
        <>
            <div className='padded-card-container flex-0'>
                <Div className='vrtl-round-editor__heading'>
                    <div className='vrtl-round-editor__heading-text'>Round Information</div>
                </Div>
                <Round.rowHeading {...tranche} entityPage prefix='vrtl-round-card--old' />
                <EditSwitch.switch canEdit={canEdit}>
                    <Round.row {...roundProps} prefix='vrtl-round-card--old' entityPage />
                    <Round.form {...roundProps} entityPage />
                </EditSwitch.switch>
            </div>
            <div className='row flex-1'>
                <div className='flex-1'>
                    <div className='row'>
                        <div className='padded-card-container asset-manager-container'>
                            <Div className='vrtl-round-editor__heading'>
                                <div className='vrtl-round-editor__heading-text'>Distributors</div>
                                {canEdit && (
                                    <Popup.popup>
                                        <Popup.inner>
                                            <Popup.close />
                                            <BrokerAllocationForm roundId={klass.id} />
                                        </Popup.inner>
                                        <Popup.trigger className='vrtl-round-editor__heading-action'>
                                            <AddCircle className='distributor-add-circle' />
                                        </Popup.trigger>
                                    </Popup.popup>
                                )}
                            </Div>
                            <div>
                                <Map data={klass.brokerAllocationsByRoundId.nodes}>
                                    <AssetManagerCard {...klass} />
                                </Map>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='flex-1 column'>
                    {isIssuer ? <CapitalSummary assetClass={klass} /> : ''}
                    <AllocationsCard assetClass={klass} roundId={klass.id} currentAccount={props.account} />
                </div>

            </div>
            <div className='vrtl-round-incoming-cap__container'>
                <div className='row--justify-between'>
                    <div className='vrtl-round-incoming-cap__heading'>
                        <div className='vrtl-round-incoming-cap__heading-text'>Cap Table</div>
                    </div>
                    {canEdit ? (
                        <div className='row'>
                            <Popup.popup key='1'>
                                <Popup.inner>
                                    <Div cleanProps={({ onResolve, ...properties }) => properties}>
                                        <IssuanceForm>
                                            <StatelessMultiSelect>
                                                <Delv.query
                                                    key='issuancequery'
                                                    {...MintQuery}
                                                    roundId={klass.id}
                                                >
                                                    <OrderBy.order arrayKey='capTables'>
                                                        <OrderBy.bar>
                                                            <OrderBy.select
                                                                map={(data) => ({
                                                                    id: data.id,
                                                                    value: data,
                                                                })}
                                                                className='icon-checkbox'
                                                            />
                                                            <OrderBy.filter
                                                                className='flex-2'
                                                                field='name'
                                                            >
                                                                Name
                                                            </OrderBy.filter>
                                                            <OrderBy.sort field='units'>
                                                                Units
                                                            </OrderBy.sort>
                                                        </OrderBy.bar>
                                                        <Div className='vrtl-asset-class-distributions'>
                                                            <Div className='order-by-inner'>
                                                                <Map
                                                                    key='issuancequery'
                                                                    arrayKey='_orderBy.capTables'
                                                                    noneFound={(
                                                                        <NoneFound>
                                                                            No closed
                                                                            distributions found.
                                                                        </NoneFound>
                                                                    )}
                                                                >
                                                                    <DistributionCard />
                                                                </Map>
                                                            </Div>
                                                        </Div>
                                                    </OrderBy.order>
                                                </Delv.query>
                                            </StatelessMultiSelect>
                                        </IssuanceForm>
                                    </Div>
                                </Popup.inner>
                                <Popup.trigger className='card-form-trigger'>
                                    Issue
                                </Popup.trigger>
                            </Popup.popup>
                            <Popup.popup>
                                <Popup.inner>
                                    <Div cleanProps={({ onResolve, ...properties }) => properties}>
                                        <MultiSelect>
                                            <ProofOfIssuance.form>
                                                <Delv.query
                                                    query={HoldingsQuery.query}
                                                    format={HoldingsQuery.format}
                                                    args={HoldingsQuery.args}
                                                    roundId={klass.id}
                                                >
                                                    <OrderBy.order>
                                                        <OrderBy.bar>
                                                            <OrderBy.filter className='flex-2' field='contactName'>
                                                                Contact Name
                                                            </OrderBy.filter>
                                                            <OrderBy.filter className='flex-2' field='legalName'>
                                                                Account Name
                                                            </OrderBy.filter>
                                                            <OrderBy.sort field='totalAmount'>
                                                                Total Units
                                                            </OrderBy.sort>
                                                        </OrderBy.bar>
                                                        <Div className='vrtl-asset-class-distributions'>
                                                            <Div className='order-by-inner'>
                                                                <Map
                                                                    key='holdingsQuery'
                                                                    arrayKey='_orderBy.data'
                                                                    noneFound={(
                                                                        <NoneFound>
                                                                            No investors with issued holdings found.
                                                                        </NoneFound>
                                                                    )}
                                                                >
                                                                    <ProofOfIssuance.row />
                                                                </Map>
                                                            </Div>
                                                        </Div>
                                                    </OrderBy.order>
                                                </Delv.query>
                                            </ProofOfIssuance.form>
                                        </MultiSelect>
                                    </Div>
                                </Popup.inner>
                                <Popup.trigger className='card-form-trigger'>
                                    Proof of Issuance
                                </Popup.trigger>
                            </Popup.popup>
                        </div>
                    ) : (
                        <div />
                    )}
                </div>

                {
                    /*
                        this spit is because issuers needed pagination first, so the queries got split here into two queries
                        the queries.js file in this shared directory does not query or format for issuers, its basically a new page for them
                        but, rewriting the structure of these pages isn't worth the time right now.
                        */
                    <div className='vrtl-asset-class-pagination'>
                        <div className='vrtl-asset-class-pagination-div'><ManagePagination key='mpagination' {...props} klass={klass} /></div>
                        {isIssuer && <div className='vrtl-asset-class-pagination-div'><HoldingPagination key='hpagination' {...props} klass={klass} /></div>}
                    </div>
                }
            </div>
        </>
    )
}

export default Manage
