import * as React from 'react'
import * as DateFns from 'date-fns'
import DataGrid from 'common/DataGrid'

import LinearProgress from 'common/LinearProgress'
import DataGridFooter from 'common/DataGridFooter'
import Typography from 'common/Typography'
import { GridNoneFound } from 'common/NoneFound'

import useQuery from 'delv/hooks/use-query.js'
import { argsToString } from 'delv/services/query-arg-service'

import DataGridToolbar from 'common/DataGridToolbar'
import useArgs from 'hooks/UseArgs'

import { NumberCell } from 'common/DataGridCell'

import Holding from 'components/holding/index.js'

const holdings = {
    query: (props) => (args) => `{
            allHoldings ${args.replace('(', `(condition:{securityId:"${props.klass.securityBySecurityId.id}"}, `)} {
                totalCount
                nodes{
                    securityId
                    ${Holding.fragment.toString()}
                    accountByInvestorId {
                        id
                        contactName
                    }
                }}
            
        }`,
    format: (data) => {
        if (!data.allHoldings) {
            return true
        }
        const capTables = []
        if (data.error) {
            throw data.error
        }
        if (data.allHoldings && data.allHoldings.totalCount) {
            data.allHoldings.nodes.forEach(
                (holding) => {
                    // eslint-disable-next-line
                    if (holding.amount != 0) {
                        capTables.push({
                            id: holding.id,
                            amount: holding.amount,
                            status: 'issued',
                            name: holding.accountByInvestorId.contactName,
                            purchasePrice: 'N\a', // Format.number(new Decimal(securityBySecurityId.unitValue).mul(holding.amount)),
                            isBrokerDealer: false,
                            issuanceType: 'holding',
                        })
                    }
                },
            )
        }
        return {
            totalCount: data.allHoldings.totalCount,
            rows: capTables,
        }
    },
}

const columnDefaults = {
    hideable: true,
    flex: 1,
    minWidth: 100,
    filterable: false,
}

const Distributions = (props) => {
    const argUtils = useArgs({
        foo: 1,
        pagination: {
            pageSize: 50,
        },
    })

    const { data, loading } = useQuery(holdings.query({
        klass: props.klass,
    }), {
        args: argsToString(argUtils.args),
        format: holdings.format,
        cacheProcess: 'infiniteScroll',
        networkPolicy: 'network-only',
    })

    const rows = data?.rows || []

    return (
        <DataGrid
            disableColumnResize={false}
            disableColumnPinning
            rows={rows}
            columns={[{
                ...columnDefaults,
                field: 'name',
                headerName: 'Investor',
                minWidth: 150,
                renderCell: ({ value }) => (
                    <Typography variant='subtitle2'>
                        {value}
                    </Typography>
                ),
            },
            {
                ...columnDefaults,
                field: 'amount',
                headerName: 'Units',
                renderCell: NumberCell,
            },
            // {
            //     ...columnDefaults,
            //     field: 'value',
            //     headerName: 'Value',
            //     renderCell: NumberCell,
            // },
            {
                ...columnDefaults,
                field: 'status',
                headerName: 'Status',
            }]}
            loading={loading}
            rowThreshold={0}
            sx={{
                border: 'none',
                backgroundColor: '#ffffff',
                '& .Mui-selected': {
                    background: 'transparent !important',
                },
            }}
            components={{
                LoadingOverlay: LinearProgress,
                Footer: DataGridFooter,
                NoRowsOverlay: GridNoneFound,
                Toolbar: DataGridToolbar,
            }}
            componentsProps={{
                noRowsOverlay: {
                    children: 'No Holdings Found',
                },
                toolbar: {
                    filter: false,
                    export: true,
                    printOptions: {
                        disableToolbarButton: true,
                    },
                    csvOptions: {
                        fileName: `holdings_vertalo_${DateFns.format(new Date(), 'MM_dd_yyyy')}`,
                    },
                },
                footer: {
                    rows,
                    totalCount: data?.totalCount,
                },
            }}
            totalCount={data?.totalCount}
            {...argUtils}
        />
    )
}

export default Distributions
