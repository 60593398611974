import Buyer from 'distributeAllocation/Buyer/index.js'
import Account from 'components/account/index.js'
import Security from 'components/security/index.js'
import Holding from 'components/holding/index.js'
import Round from 'components/round/index.js'
import Asset from 'components/asset/index.js'
import HoldingBCA from 'components/holdingBlockchainAddress/index.js'
import { Decimal } from 'decimal.js'

const TokenizeQuery = {
    query: (id) => `{
        roundById(id: "${id}") {
          ${Round.fragment.toString()}
          securityBySecurityId {
              ${Security.fragment.toString()}
            holdingsBySecurityId {
              nodes {
                  ${Holding.fragment.toString()}
                accountByInvestorId {
                    ${Account.fragment.toString()}
                    holdings
                }
                holdingBlockchainAddressesByHoldingId(condition: {status: "trade"}) {
                  nodes {
                    ${HoldingBCA.fragment.toString()}
                  }
                }
              }
            }
          }
        }
      }`,
    args: (props) => props.roundId,
    format: ({ roundById: { securityBySecurityId } }, { multiSelect }) => {
        const capTables = []
        securityBySecurityId.holdingsBySecurityId.nodes.forEach(
            (item) => {
                if (item.holdingBlockchainAddressesByHoldingId.nodes[0].blockchainAddressByBlockchainAddressId.chain === 'vertalon-hawknet') {
                    const capTable = {
                        id: item.id,
                        name: item.accountByInvestorId.contactName,
                        units: item.amount,
                        investor: item.accountByInvestorId,
                        isSelected: multiSelect.includes(item.id),
                        onClick: () => {
                            multiSelect.select({
                                id: item.id,
                                value: {
                                    id: item.id,
                                },
                            })
                        },
                    }
                    capTables.push(capTable)
                }
            },
        )

        capTables.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
            return 0
        })
        return { capTables }
    },
    networkPolicy: 'network-only',
}

const RoundPage = {
    issuerClassQuery: `
    id
    name
    holdings
    managedUnits
    termsUrl
    opensOn
    closesOn
    price
    total
    status
    securityBySecurityId {
        ${Security.fragment.toString()}
    }
    trancheBondDatumByRoundId{
        ${Round.tranche.fragment.toString()}
    }
    allocationsByRoundId {
        nodes {
            id
            name
            status
            opensOn
            closesOn
            managedUnits
            accountByOwnerId {
                id
                name
            }
        }
    }
    assetByAssetId{
      id
      name
      type
    }
    brokerAllocationsByRoundId {
      nodes {
        id
        managedUnits
        name
        status
      }
    }`,
    classQuery: `
    id
    name
    holdings
    managedUnits
    termsUrl
    opensOn
    closesOn
    price
    total
    status
    securityBySecurityId {
        ${Security.fragment.toString()}
    }
    trancheBondDatumByRoundId{
        ${Round.tranche.fragment.toString()}
    }
    allocationsByRoundId {
        nodes {
            id
            name
            status
            opensOn
            closesOn
            managedUnits
            accountByOwnerId {
                id
                name
            }
            distributionsByAllocationId{
              nodes{
                id
                amount
                status
                accountByInvestorId{
                  id
                  contactName
                  email
                }
              }
            }
        }
    }
    assetByAssetId{
      id
      name
      type
    }
    brokerAllocationsByRoundId {
      nodes {
        id
        managedUnits
        name
        status
      }
    }`,
    capitalDeployments: `
    capitalDeploymentsByRoundId {
        nodes {
            id
            amount
            paidOn
            note
            currencyByCurrency {
                id
                code
            }
        }
    }`,
    query: ({ id, isIssuer }) => {
        const accountFragment = `securityBySecurityId {
            ${Security.fragment.toString()}
            securityListingBySecurityId{
                id
                accountByAtsId{
                  id
                  name
                }
            }
        }`
        if (isIssuer) {
            return `{
              roundById(id: "${id}") {
                ${RoundPage.issuerClassQuery}
                ${RoundPage.capitalDeployments}
                ${accountFragment}
              }
            }`
        }
        // this query use to use the roungPage.classQuery but im lazy and it cant have the holdings queries
        return `{
          roundById(id: "${id}") {
              ${RoundPage.classQuery}
          }
        }`
    },
    args: (props) => ({
        id: props.match.params.round_id,
        isIssuer: props.account.type === 'issuer',
    }),
    format: (data) => {
        const klass = data.roundById
        if (data.error) {
            throw data.error
        }

        if (klass.securityBySecurityId?.securityListingBySecurityId?.accountByAtsId) {
            klass.atsName = klass.securityBySecurityId.securityListingBySecurityId.accountByAtsId.name
        }

        return {
            klass,
        }
    },
}

export { RoundPage }

const MintQuery = {
    query: (id) => `{
  roundById(id: "${id}") {
    id
    allocationsByRoundId {
      nodes {
        id
        name
        status
        distributionsByAllocationId(condition: { status: "closed" }, filter: { amount: { greaterThan: "0" } }) {
            nodes {
                ${Buyer.fragment.toString()}
            }
        }
      }
    }
  }
}`,
    args: (props) => props.roundId,
    format: (data, { multiSelect }) => {
        const { roundById: { allocationsByRoundId } } = data
        const capTables = []
        function handleAllocation(allocation) {
            let total = 0
            // eslint-disable-next-line
            let entries = 0
            allocation.distributionsByAllocationId.nodes.forEach(
                (item) => {
                    total = Decimal.add(item.amount, total)
                    entries += 1
                    const capTable = {
                        id: item.id,
                        name: item.accountByInvestorId.contactName,
                        units: item.amount,
                        investor: item.accountByInvestorId,
                        isSelected: multiSelect.includes(item.id),
                        onClick: () => {
                            multiSelect.select({
                                id: item.id,
                                value: {
                                    id: item.id,
                                },
                            })
                        },
                    }
                    capTables.push(capTable)
                },
            )

            capTables.sort((a, b) => {
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1
                }
                return 0
            })
        }

        allocationsByRoundId.nodes.forEach(handleAllocation)
        return { capTables }
    },
}

const AllocationsQuery = {
    query: (id) => `{
    roundById(id: "${id}") {
        id
        allocationsByRoundId {
            nodes {
                id
                name
                status
                opensOn
                closesOn
                managedUnits
                accountByOwnerId {
                    id
                    name
                }
            }
        }
    }
}`,
    args: (props) => props.roundId,
    format: (
        { roundById: { allocationsByRoundId } },
    ) => ({
        allocations: {
            nodes: allocationsByRoundId.nodes,
        },
    }),
}

const CapitalDeployments = {
    query: (id) => `{
    roundById(id: "${id}") {
        id
        price
        name
        allocationsByRoundId {
            nodes {
                id
                accountByOwnerId {
                    id
                    name
                }
                distributionsByAllocationId(filter:{status:{notEqualTo:"deleted"}}) {
                    nodes {
                        id
                        status
                        amount
                        accountByInvestorId {
                            id
                            contactName
                        }
                        distributionPaymentsByDistributionId {
                            nodes {
                                id
                                amount
                                paidOn
                                currencyByCurrency {
                                    id
                                    code
                                }
                            }
                        }
                    }
                }
            }
        }
        capitalDeploymentsByRoundId {
            nodes {
                id
                amount
                paidOn
                note
                currencyByCurrency {
                    id
                    code
                }
            }
        }
    }
}`,
    args: (props) => props.match.params.round_id,

    format: (data) => {
        const klass = data.roundById

        const entries = klass.allocationsByRoundId.nodes.map((allocation) => allocation.distributionsByAllocationId.nodes.map(
            (distribution) => {
                const {
                    amount,
                    status,
                    accountByInvestorId: investor,
                    distributionPaymentsByDistributionId: { nodes },
                } = distribution

                const payments = nodes.map(
                    ({
                        id, amount: nodeAmount, paidOn, currencyByCurrency: { code },
                    }) => ({
                        id,
                        name: investor.name,
                        amount: nodeAmount,
                        paidOn,
                        currency: code,
                    }),
                )

                const totalPaidIn = payments.reduce(
                    (total, payment) => Decimal.add(payment.amount, total),
                    0,
                )

                return {
                    status,
                    investor,
                    payments,
                    amount,
                    totalPaidIn,
                }
            },
        ))

        const {
            capitalDeploymentsByRoundId: {
                nodes: capitalDeployments,
            },
        } = klass

        return {
            roundId: klass.id,
            entries: Array.prototype.concat.apply([], entries),
            capitalDeployments,
            price: klass.price,
        }
    },
}

const HoldingsQuery = {
    query: (id) => `{
        roundById(id: "${id}"){
            ${Round.fragment.toString()}
            assetByAssetId{
                ${Asset.fragment.toString()}
                accountByIssuerId{
                    ${Account.fragment.toString()}
                }
            }
            securityBySecurityId{
                ${Security.fragment.toString()}
                holdingsBySecurityId{
                    nodes{
                        ${Holding.fragment.toString()}
                        accountByInvestorId{
                            ${Account.fragment.toString()}
                        }
                    }
                }
            }

        }
    }`,
    args: (props) => props.roundId,
    format: ({ roundById }, { multiSelect }) => {
        const { name: assetName } = roundById.assetByAssetId
        const { type } = roundById.assetByAssetId.assetTypeByType
        const { name: roundName } = roundById
        const { name: issuerName } = roundById.assetByAssetId.accountByIssuerId

        const investors = {}
        roundById.securityBySecurityId.holdingsBySecurityId.nodes.forEach((node) => {
            const { amount, issuedOn } = node
            const { name: legalName, id, contactName } = node.accountByInvestorId

            const holding = {
                id,
                amount,
                issuedOn,
                roundName,
            }

            if (!investors[id]) {
                investors[id] = {
                    id,
                    type,
                    amount,
                    issuedOn,
                    assetName,
                    legalName,
                    issuerName,
                    contactName,
                    isSelected: multiSelect.includes(id),
                    onClick: () => {
                        multiSelect.select([{
                            id,
                            value: investors[id],
                        }])
                    },
                    holdings: [],
                    totalAmount: amount,
                }
            } else {
                const newAmount = new Decimal(investors[id].totalAmount).add(amount)
                investors[id].totalAmount = newAmount.d[0]
            }
            investors[id].holdings.push(holding)
        })

        const data = Object.values(investors)
        return {
            orderByData: data,
        }
    },
}

export {
    MintQuery, AllocationsQuery, CapitalDeployments, TokenizeQuery, HoldingsQuery,
}
