import React from 'react'
import axios from 'axios'
import { Switch, Route, Redirect } from 'react-router-dom'
import { LicenseInfo } from '@mui/x-data-grid-pro'

import AccountRole from 'accountRole/AccountRole'
import Assets from 'assets/index.js'
import Security from 'security/index.js'
import Securities from 'securities/index.js'
import Customers from 'customers/index.js'
import Dashboard from 'dashboard/index.js'
import WSTest from 'test/WSTest'
import GraphqlTest from 'test/GraphqlTest'
import Issuer from 'issuer/index.js'
import Issuers from 'issuers/index.js'
import Message from 'message/index.js'
import Messages from 'messages/index.js'
import Transaction from 'transaction/index.js'
import Transactions from 'transactions/index.js'
import Round from 'round/index.js'
import Capitalization from 'capitalization/index.js'
import DistributeAllocation from 'distributeAllocation/index.js'
import Investor from 'investor/index.js'
import { DelvReact } from 'delv/delv-react'
import UserData from 'common/userData/UserData'
import Reports from 'reports/index.js'
import Distribution from 'distribution/index.js'
import Holding from 'holding/index.js'
import Holdings from 'pages/holdings/index.js'
import Signing from 'signing/index.js'
import AccountClaim from 'accountClaim/index.js'
import OnboardingQuestions from 'onboardingQuestions/index.js'
import InvestorDocuments from 'investorDocuments/index.js'
import NorthCapital from 'northCapital/index.js'
// agent pages
import AccountAlias from 'util/AccountAlias.js'
// Page pattern
import Login from 'pages/login'
import InvestorCommitment from 'pages/offer-wall/[offer_wall_id]/offering/[offering_id]'
import Wallet from 'pages/wallet'
import AccountSettings from 'pages/settings/account'
import DelegateSettings from 'pages/settings/delegates'
import IntegrationSettings from 'pages/settings/integrations'
import QualificationAnswersSettings from 'pages/settings/qualification-answers'
import Portfolio from 'pages/portfolio'
import Distributions from 'pages/distributions'
import AccountSetup from 'pages/account_setup'
import { referenceTypeConflicts } from 'constants/delv-config.js'

import typeMap from './TypeMapData.json'

import './App.scss'

LicenseInfo.setLicenseKey('e965b7696201282b5548a86f0ad84a61T1JERVI6MzY3MDUsRVhQSVJZPTE2NzQ4NTM5MjAwMDAsS0VZVkVSU0lPTj0x')

const delvConfig = {
    url: '/api/v2/graphql',
    handleError: (error, network) => {
        const errorMap = {
            401: () => {
                window.location.href = '/login'
            },
            403: () => {
                window.location.href = '/login'
            },
        }

        if (network && error && error.response) {
            if (errorMap[error.response.status]) {
                errorMap[error.response.status]()

                return true
            }
        }
        return false
    },
    typeConflicts: referenceTypeConflicts,
    production: typeMap,
}

const generateId = () => `_${Math.random().toString(36).substr(2, 9)}`
// interesting rework for these nested views,
// lets pass in children so that its Account(subview)
// so that there is no if statment in the account component
function App() {
    window.vertalo.configure(axios)
    window.util = {
        generateId,
    }

    const makeRouteComponents = ({
        path,
        accounts,
        exact,
        component,
    }) => (
        <Route key={path} exact={exact} path={path} component={accounts ? UserData(component, { accounts }) : component} />
    )

    const makeRouteConfig = ({ accounts, ...other }) => ({
        accounts: AccountAlias.expand(accounts),
        ...other,
    })

    const routes = [
        {
            path: '/test/ws',
            component: WSTest,
        },
        {
            path: '/test/graphql',
            component: GraphqlTest,
        },
        {
            path: '/contacts',
            component: Customers,
            accounts: ['nonInvestor'],
            exact: true,
        },
        {
            path: '/holding/:holding_id',
            component: Holding,
            accounts: ['investor', 'issuer'],
            exact: true,
        },
        {
            path: '/holdings',
            component: Holdings,
            accounts: ['issuer'],
            exact: true,
        },
        {
            path: '/investor-documents',
            component: InvestorDocuments,
            accounts: ['investor'],
        },
        {
            path: '/securities',
            component: Securities,
            accounts: ['ats', 'issuer'],
            exact: true,
        },
        {
            path: '/security/:security_id',
            component: Security,
            accounts: ['ats', 'issuer'],
            exact: true,
        },
        {
            component: Login,
            exact: true,
            path: '/login',
        },
        {
            accounts: ['investor'],
            component: InvestorCommitment,
            path: '/offer-wall/:offer_wall_id/offering/:offering_id',
        },
        {
            accounts: ['investor'],
            exact: true,
            component: Portfolio,
            path: '/portfolio',
        },
        {
            accounts: ['issuer', 'broker', 'agent'],
            exact: true,
            component: Distributions,
            path: '/distributions',
        },
        {
            accounts: ['investor'],
            exact: true,
            component: Wallet,
            path: '/wallet',
        },
        {
            path: '/account/setup',
            component: AccountSetup,
            accounts: ['all'],
            exact: true,
        },
        {
            path: '/settings/account',
            component: AccountSettings,
            accounts: ['all'],
            exact: true,
        },
        {
            path: '/settings/qualification-answers',
            component: QualificationAnswersSettings,
            accounts: ['investor'],
            exact: true,
        },
        {
            path: '/settings/delegates',
            component: DelegateSettings,
            accounts: ['all'],
            exact: true,
        },
        {
            path: '/settings/integrations',
            component: IntegrationSettings,
            accounts: ['core'],
            exact: true,
        },
        {
            path: '/north-capital/issuers/:issuer_id?/:instrument_id?/:payment_id?',
            component: NorthCapital,
            accounts: ['issuer', 'broker'],
        },
    ].map(makeRouteConfig)
        .map(makeRouteComponents)

    return (
        <DelvReact config={delvConfig}>
            <div className='main-app'>
                <Switch>
                    {routes}
                    <Redirect from='/settings' to='/settings/account' />
                    <Route
                        exact
                        path='/account/claim'
                        component={AccountClaim}
                    />
                    <Route
                        exact
                        path='/distribution/:distribution_id'
                        component={UserData(Distribution, {
                            accounts: ['investor'],
                        })}
                    />
                    <Route exact path='/account-role' component={AccountRole} />
                    <Route
                        path='/docusign/templates/:template_id?/:mapped_template_id?/:document_id?'
                        component={UserData(Signing, {
                            accounts: ['issuer', 'broker'],
                        })}
                    />
                    <Route
                        path='/docusign/test/:template_id/:mapped_template_id'
                        component={UserData(Signing, {
                            accounts: ['issuer', 'broker'],
                        })}
                    />
                    <Route
                        exact
                        path='/assets'
                        component={UserData(Assets, {
                            accounts: ['broker', 'issuer'],
                        })}
                    />
                    <Route path='/security/:security_id' component={UserData(Security, { accounts: ['broker', 'issuer'] })} />
                    <Route
                        exact
                        path='/issuers'
                        component={UserData(Issuers, { accounts: ['broker'] })}
                    />
                    <Route
                        path='/issuer/:issuer_id'
                        component={UserData(Issuer, {
                            accounts: ['broker', 'investor'],
                        })}
                    />
                    <Route
                        path='/broker/:issuer_id'
                        component={UserData(Issuer, {
                            accounts: ['broker', 'investor'],
                        })}
                    />
                    <Route
                        path='/investor/:investor_id'
                        component={UserData(Investor, {
                            accounts: ['broker', 'issuer', 'investor'],
                        })}
                    />
                    <Route
                        path='/message/:message_id'
                        component={UserData(Message, {
                            accounts: ['broker', 'issuer', 'investor'],
                        })}
                    />
                    <Route
                        exact
                        path='/messages'
                        component={UserData(Messages, {
                            accounts: ['broker', 'issuer', 'investor'],
                        })}
                    />
                    <Route
                        exact
                        path='/transactions'
                        component={UserData(Transactions, {
                            accounts: ['broker', 'issuer', 'investor'],
                        })}
                    />
                    <Route
                        path='/holding/:holding_id'
                        component={UserData(Issuers, {
                            accounts: ['broker', 'issuer', 'investor'],
                        })}
                    />

                    <Route
                        path='/transaction/:transaction_id'
                        component={UserData(Transaction, {
                            accounts: ['broker', 'issuer', 'investor'],
                        })}
                    />
                    <Route
                        exact
                        path='/asset-class/:round_id/:view'
                        component={UserData(Round, {
                            accounts: ['broker', 'issuer', 'investor'],
                        })}
                    />

                    <Route
                        path='/capitalization'
                        component={UserData(Capitalization, {
                            accounts: ['issuer', 'broker'],
                        })}
                    />
                    <Route
                        path='/distribute-allocation/:allocation_id/:view?'
                        component={UserData(DistributeAllocation, {
                            accounts: ['broker', 'issuer'],
                        })}
                    />

                    <Route
                        path='/reports'
                        component={UserData(Reports, {
                            accounts: ['issuer', 'broker'],
                        })}
                    />
                    <Route
                        path='/dashboard'
                        component={UserData(Dashboard, {
                            accounts: ['broker', 'issuer'],
                        })}
                    />
                    <Route
                        exact
                        path='/onboarding-questions'
                        component={UserData(OnboardingQuestions, {
                            accounts: ['broker', 'issuer'],
                        })}
                    />
                    <Redirect from='/*' to='/login' />
                </Switch>
            </div>
        </DelvReact>
    )
}

export default App
