import * as React from 'react'
import * as DateFns from 'date-fns'
import DataGrid from 'common/DataGrid'

import LinearProgress from 'common/LinearProgress'
import DataGridFooter from 'common/DataGridFooter'
import Typography from 'common/Typography'
import { GridNoneFound } from 'common/NoneFound'

import useQuery from 'delv/hooks/use-query.js'
import { argsToString } from 'delv/services/query-arg-service'

import DataGridToolbar from 'common/DataGridToolbar'
import useArgs from 'hooks/UseArgs'

import { NumberCell } from 'common/DataGridCell'

const RoundPage = {
    // eslint-disable-next-line
    // query: (props) => (args) => `{
    //         allDistributions ${args.replace('(', `(filter:{status:{notEqualTo:"locked"}}, condition:{roundId:"${props.klass.id}"}, `)}{
    //             totalCount
    //                 nodes{
    //                 id
    //                 amount
    //                 status
    //                 issuanceStatus
    //                 roundId
    //                 accountByInvestorId{
    //                     id
    //                     contactName
    //                     email
    //                 }
    //                 }}
    //     }`,
    query: (props) => (args) => `{
            allDistributions ${args.replace('(', `(condition:{roundId:"${props.klass.id}"}, `)}{
                totalCount
                    nodes{
                    id
                    amount
                    status
                    issuanceStatus
                    roundId
                    accountByInvestorId{
                        id
                        contactName
                        email
                    }
                    }}
        }`,
    format: (data) => {
        const capTables = []
        if (data.error) {
            throw data.error
        }
        data.allDistributions.nodes.forEach((distribution) => {
            // eslint-disable-next-line
            if (distribution.status != 'locked' || (distribution.status == 'locked' && distribution.issuanceStatus != null && distribution.issuanceStatus != 'approved')) {
                capTables.push({
                    id: distribution.id,
                    amount: distribution.amount,
                    status: distribution.status === 'locked' ? distribution.issuanceStatus : distribution.status,
                    name: distribution.accountByInvestorId.contactName,
                    purchasePrice: 'N/A',
                    isBrokerDealer: false,
                    issuanceType: 'distribution',
                })
            }
        })

        return {
            totalCount: data.allDistributions.totalCount,
            rows: capTables,
        }
    },
}

const columnDefaults = {
    hideable: true,
    flex: 1,
    minWidth: 100,
    filterable: false,
}

const Distributions = (props) => {
    const argUtils = useArgs({
        foo: 1,
        pagination: {
            pageSize: 50,
        },
    })

    const { data, loading } = useQuery(RoundPage.query({
        klass: props.klass,
    }), {
        args: argsToString(argUtils.args),
        format: RoundPage.format,
        cacheProcess: 'infiniteScroll',
        networkPolicy: 'network-only',
    })

    const rows = data?.rows || []

    return (
        <DataGrid
            disableColumnResize={false}
            disableColumnPinning
            rows={rows}
            columns={[{
                ...columnDefaults,
                field: 'name',
                headerName: 'Investor',
                minWidth: 150,
                renderCell: ({ value }) => (
                    <Typography variant='subtitle2'>
                        {value}
                    </Typography>
                ),
            },
            {
                ...columnDefaults,
                field: 'amount',
                headerName: 'Units',
                renderCell: NumberCell,
            },
            // {
            //     ...columnDefaults,
            //     field: 'value',
            //     headerName: 'Value',
            //     renderCell: NumberCell,
            // },
            {
                ...columnDefaults,
                field: 'status',
                headerName: 'Status',
            }]}
            loading={loading}
            rowThreshold={0}
            sx={{
                border: 'none',
                backgroundColor: '#ffffff',
                '& .Mui-selected': {
                    background: 'transparent !important',
                },
            }}
            components={{
                LoadingOverlay: LinearProgress,
                Footer: DataGridFooter,
                NoRowsOverlay: GridNoneFound,
                Toolbar: DataGridToolbar,
            }}
            componentsProps={{
                noRowsOverlay: {
                    children: 'No Distributions Found',
                },
                toolbar: {
                    filter: false,
                    export: true,
                    printOptions: {
                        disableToolbarButton: true,
                    },
                    csvOptions: {
                        fileName: `distributions_vertalo_${DateFns.format(new Date(), 'MM_dd_yyyy')}`,
                    },
                },
                footer: {
                    rows,
                    totalCount: data?.totalCount,
                },
            }}
            totalCount={data?.totalCount}
            {...argUtils}
        />
    )
}

export default Distributions
